import { Component, OnInit } from '@angular/core';
import { ReportService } from '../services/report-management/report.service';
import { ImageBaseUrls } from '../services/config/config';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.css']
})
export class CustomerDetailsComponent implements OnInit {

   public ImageBaseUrls: any = ImageBaseUrls;
    public records:any;
    public collection = { count: 60, message: '', data: [] };
    public new_collection = { count: 60, message: '', data: [] };
    public config = {
      id: 'custom',
      itemsPerPage: 5,
      currentPage: 1,
      totalItems: this.collection.count
    };

    panelIcons = {
      panel1: '➕',
      panel2: '➕',
    };
  
    toggleIcon(panel: string) {
      if (panel === 'panel1') {
        this.panelIcons.panel1 = this.panelIcons.panel1 === '➕' ? '➖' : '➕';
      } else if (panel === 'panel2') {
        this.panelIcons.panel2 = this.panelIcons.panel2 === '➕' ? '➖' : '➕';
      }
    }
  
    private formData = {
      id: '',
      name: '',
      orderby: 'id asc',
      page: this.config.currentPage,
      recordsPerPage: this.config.itemsPerPage
    };
  
    public showingRecords: any = {};
    public paginationLoader = false;
    public orderKey: string = 'policy.id';
    public orderDir: string = 'asc';
    public reverse: boolean = false;
    public searchByName: string;
    customerId: string;

    constructor(private router: Router, private location: Location, private toastr: ToastrService, private ReportService: ReportService,private route: ActivatedRoute) {
  
    }
    customerDetails: any;

    closeModal() {
      // window.close();
      

            window.close();

    }

   
    ngOnInit() {
     
      this.customerId = this.route.snapshot.paramMap.get('id')!;
      console.log(this.customerId)
      this.customer_details()
      const navigation = this.location.getState();

    if (navigation && navigation['customerDetails']) {
      this.customerDetails = navigation['customerDetails'];
    } else {
      this.customerDetails = {}; 
    }
  

      this.formData = {
        id: '',
        name: '',
        orderby: this.orderKey + ' ' + this.orderDir,
        page: this.config.currentPage,
        recordsPerPage: this.config.itemsPerPage
      };
      this.CustomerReportList();
    }
  
    CustomerReportList() {
      this.paginationLoader = true;
      this.ReportService.CustomerDetailsList(this.formData,this.customerId).subscribe(
        (res: any) => {
          console.log(res,'dsjfjkdsjk');
          
          this.collection.message = res.message;
          this.collection.data = res.data.records[0];
          console.log( this.collection.data);
          
          this.collection.count = res.data.totalRecords;
          this.config.currentPage = this.formData.page;
          this.config.totalItems = this.collection.count;
          this.paginationLoader = false;
  
          let pageRecords = this.config.currentPage * this.config.itemsPerPage;
  
          this.showingRecords = {
            from: pageRecords - (this.config.itemsPerPage - 1),
            to: (pageRecords > this.config.totalItems) ? this.config.totalItems : pageRecords,
            total: this.config.totalItems
          };
  
        }
      )
    }
  
    sortData(orderKey: string): void {
      this.orderKey = orderKey;
      this.orderDir = this.orderDir === 'asc' ? 'desc' : 'asc';
      this.formData.page = this.config.currentPage;
      this.formData.orderby = this.orderKey + ' ' + this.orderDir;
      this.reverse = !this.reverse;
      this.CustomerReportList();
    }
  
    pageChanged(page: number): void {
      this.config.currentPage = page;
      this.formData.page = this.config.currentPage;
      this.CustomerReportList();
    }
  
      searchData(): void {
        console.log('Search term:', this.searchByName); // Check input value
        this.formData.page = 1; // Reset to the first page for a new search
        this.formData.name = this.searchByName.trim(); // Pass the search value
        this.CustomerReportList_filter(); // Call the API to fetch filtered data
    }
  
  
    listdata:any;
    CustomerReportList_filter(): void {
      this.paginationLoader = true;
      this.ReportService.CustomerReportList(this.formData).subscribe(
          (res: any) => {

            this.listdata = res.data.records;
            this.listdata = this.listdata.filter((item: any) =>
              (item.full_name || '').toLowerCase().includes(this.searchByName.toLowerCase())
            );
    
            this.collection.message = res.message;
            this.collection.data = res.data.records; // Ensure `res.data.records` contains filtered data
            this.collection.count = res.data.totalRecords;
            this.config.currentPage = this.formData.page;
            this.config.totalItems = this.collection.count;
            this.paginationLoader = false;
            
            let pageRecords = this.config.currentPage * this.config.itemsPerPage;
            console.log('API Response:', res); // Log the response
            this.collection.data = this.listdata;
              this.showingRecords = {
                  from: pageRecords - (this.config.itemsPerPage - 1),
                  to: (pageRecords > this.config.totalItems) ? this.config.totalItems : pageRecords,
                  total: this.config.totalItems,
              };
          },
          (error: any) => {
              console.error('Error fetching customer data', error);
              this.paginationLoader = false;
          }
      );
  }
  
  
    showEntries(event): void {
      this.formData.page = 1;
      this.formData.recordsPerPage = event.target.value;
      this.config.itemsPerPage = event.target.value;
      this.CustomerReportList();
    }
  
   view(id){
     let formData={
      id: id,
      name: "",
      orderby: "policy.id asc",
      page: 1,
      recordsPerPage: 5
     }
     this.ReportService.TransactionReportList(formData).subscribe(
       (res:any)=>{
         this.records=res.data.records[0]
       }
     )
   }
   
    policies = [];
   customer_details() {
    this.ReportService.CustomerDetails(this.customerId).subscribe(
      (res: any) => {
        console.log(res.data.records,'jhsfdjds');
        this.policies = res.data.records;
        // Success response
        this.new_collection.data = res.data.records;
      },
      (error: any) => {
        // Error response
        console.error('Error fetching customer details:', error);
        this.toastr.error('Error','No data found')
      }
    );
  }
  
   
  
  }
