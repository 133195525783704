import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import {apiBaseUrls} from '../config/config';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuManagementService {

  private apiBaseUrls:string=apiBaseUrls;
  private token= window.localStorage.getItem('token');
  
  constructor( private http: HttpClient) {}
  
}
