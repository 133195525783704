import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, ActivatedRoute } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { Select2Module } from 'ng2-select2';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {IndexComponent} from './layouts/index/index.component';
import {HeaderComponent} from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { SingleColumnComponent } from './layouts/single-column/single-column.component';
import { SidebarComponent } from '../app/shared/sidebar/sidebar.component';
import { TwoColumnComponent } from './layouts/two-column/two-column.component';
import { ThreeColumnComponent } from './layouts/three-column/three-column.component';
import { NoColumnComponent } from './layouts/no-column/no-column.component';
import { LoginComponent } from './modules/login/login.component';
import { services } from './app.services';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { ForgetPasswordComponent } from './modules/forget-password/forget-password.component';
import { ResetPasswordComponent } from './modules/reset-password/reset-password.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {NgxPaginationModule} from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { PageNotFoundComponent } from './modules/page-not-found/page-not-found.component';
import { SharedModule } from './shared/shared.module';
import { TreeViewModule } from '@syncfusion/ej2-angular-navigations';
import { NgSelectConfig } from '@ng-select/ng-select'; 
import { ɵs } from '@ng-select/ng-select';
import { ChangePasswordComponent } from './modules/change-password/change-password.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { MomentModule } from 'angular2-moment'; // optional, provides moment-style pipes for date formatting
import { NgOtpInputModule } from  'ng-otp-input';
import { InspectionModule } from './modules/inspection/inspection.module';
import 'video.js/dist/video-js.css';
import { ChartsModule } from 'ng2-charts';
import { CustomerDetailsComponent } from './customer-details/customer-details.component';

@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    HeaderComponent,
    FooterComponent,
    SingleColumnComponent,
    SidebarComponent,
    TwoColumnComponent,
    ThreeColumnComponent,
    NoColumnComponent,
    LoginComponent,
    CustomerDetailsComponent,
    DashboardComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    PageNotFoundComponent,
    ChangePasswordComponent,

  ],
  imports: [
    ChartsModule,
    NgOtpInputModule,
    NgIdleKeepaliveModule.forRoot(),
    MomentModule,
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    SharedModule,
    Ng2SearchPipeModule,
    NgxPaginationModule,
    Select2Module,
    InspectionModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      //disableTimeOut: true,
      //tapToDismiss: false,
      // toastClass: "toast border-red",
      closeButton: true
      // positionClass:'bottom-left'
    }), // ToastrModule added
    ReactiveFormsModule

  ],
  providers: [services,NgSelectConfig,ɵs],
  bootstrap: [AppComponent]
})
export class AppModule { }
