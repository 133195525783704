import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { apiBaseUrls } from '../config/config';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  private apiBaseUrls: string = apiBaseUrls;
  private token = window.localStorage.getItem('token');

  constructor(private http: HttpClient) { }

  CustomerList(formData) {
    let Url = this.apiBaseUrls + 'customers/list'
    var header = {
      headers: new HttpHeaders()
        .set('Content-Type', `application/json`)
        .set('Authorization', `${'Bearer: ' + this.token}`)
    }
    return this.http.post(`${Url}`, formData, header);
  }

  Inspection_verify(formData) {
    let Url = this.apiBaseUrls + 'inspection/productsinspections';
    var header = {
      headers: new HttpHeaders()
        .set('Authorization', `${'Bearer: ' + this.token}`)
    }
    return this.http.post(`${Url}`, formData, header);
  }


  CustomerList_(formData) {

    let Url = this.apiBaseUrls + 'inspection/list'
    var header = {
      headers: new HttpHeaders()
        .set('Content-Type', `application/json`)
        .set('Authorization', `${'Bearer: ' + this.token}`)
    }
    return this.http.get(`${Url}`, header);
  }


  booking_list_(formData) {

    let Url = this.apiBaseUrls + 'inspection/agency_dashboard_details'
    var header = {
      headers: new HttpHeaders()
        .set('Content-Type', `application/json`)
        .set('Authorization', `${'Bearer: ' + this.token}`)
    }
    return this.http.get(`${Url}`, header);
  }

  BookingList_(managerId: number) {
    let Url = this.apiBaseUrls + `inspection/userlist/${managerId}`;
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer: ${this.token}`);
    return this.http.get(`${Url}`, { headers });
  }

  customer_list(managerId: number) {
    let Url = this.apiBaseUrls + `inspection/bookedslotlist/` + managerId;
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer: ${this.token}`);
    return this.http.get(`${Url}`, { headers });
  }

  customer_listt() {
    let Url = this.apiBaseUrls + `inspection/bookedslotlist` 
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer: ${this.token}`);
    return this.http.get(`${Url}`, { headers });
  }


  inspection_detail(managerId: number) {
    let Url = this.apiBaseUrls + `inspection/bookedslotlist/` + managerId;
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer: ${this.token}`);
    return this.http.get(`${Url}`, { headers });
  }

  booking_list() {
    let Url = this.apiBaseUrls + `inspection/staff_dashboard_details`;
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer: ${this.token}`);
    return this.http.get(`${Url}`, { headers });
  }



  inspection_customer_detail(id) {
    let Url = this.apiBaseUrls + `inspection/inspection_details/${id}`;
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer: ${this.token}`);
    return this.http.get(`${Url}`, { headers });
  }

  inspected_customer_list(id) {
    let Url = this.apiBaseUrls + `inspection/inspection_details/${id}`;
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer: ${this.token}`);
    return this.http.get(`${Url}`, { headers });
  }

  deleteCustomer(id) {
    let Url = this.apiBaseUrls + 'customers/delete/' + id;
    var header = {
      headers: new HttpHeaders()
        .set('Content-Type', `application/json`)
        .set('Authorization', `${'Bearer: ' + this.token}`)
    }
    return this.http.delete(`${Url}`, header);
  }

  view(id) {
    let Url = this.apiBaseUrls + 'customers/show/' + id;
    var header = {
      headers: new HttpHeaders()
        .set('Content-Type', `application/json`)
        .set('Authorization', `${'Bearer: ' + this.token}`)
    }
    return this.http.get(`${Url}`, header);
  }

  StatusUpdate(id) {
    let Url = this.apiBaseUrls + 'customers/statusUpdate/' + id;
    var header = {
      headers: new HttpHeaders()
        .set('Content-Type', `application/json`)
        .set('Authorization', `${'Bearer: ' + this.token}`)
    }
    return this.http.get(`${Url}`, header);
  }


  approve_by_staff(formData) {
    let Url = this.apiBaseUrls + 'inspection/productsinspections';
    var header = {
      headers: new HttpHeaders()
        .set('Authorization', `${'Bearer: ' + this.token}`)
    }
    return this.http.post(`${Url}`, formData, header);
  }

}
