import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiBaseUrls } from '../config/config';
import { AuthService } from '../Auth/auth.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InsuranceCategoryService {
  private headersData:any = {};
  private tokenData:any = {};

  private apiUrl:any = {
    listing: apiBaseUrls + 'insurance_category/list',
    create: apiBaseUrls + 'insurance_category/create',
    edit: apiBaseUrls + 'insurance_category/show/',
    update: apiBaseUrls + 'insurance_category/update/',
    delete: apiBaseUrls + 'insurance_category/delete/',
    categories: apiBaseUrls + 'insurance_category/getICategories/',
    categories_types: apiBaseUrls + 'insurance_category/categories/type'
  };
  
  constructor(private authService: AuthService, private http: HttpClient) {
  }

  apiCall(apiType:any, formData:any = null) {
    let Url = this.apiUrl[apiType];

    this.headersData = this.authService.setTokenHeader();
    this.tokenData = this.authService.setTokenHeaderOnly();
    
    switch(apiType) {
      case 'create':
        return this.http.post(Url, formData, this.tokenData);
      break;

      case 'edit':
        Url = Url + formData;
        return this.http.get(Url, this.headersData);
      break;

      case 'update':
        Url = Url + formData.get('id');
        return this.http.put(Url, formData, this.tokenData);
      break;

      case 'delete':
        Url = Url + formData;
        return this.http.delete(Url, this.headersData);
      break;

      case 'listing':
      default:
        return this.http.post(Url, formData, this.headersData);
      break;

      case 'categories':
        Url = Url;
        if(formData.id > 0) {
          Url = Url + "/" + formData.id;
        }
        return this.http.get(Url, this.headersData);
      break;

      case 'categories_types':
        return this.http.get(Url, this.headersData);
      break;
    }
  }

  // private apiBaseUrls: string = apiBaseUrls;
  // private token = window.localStorage.getItem('token');

  // constructor(private http: HttpClient) { }


  // InsuranceCategoryList(formData) {

  //   let Url = this.apiBaseUrls + 'insurance_category/' + 'list'
  //   // alert(Url)
  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', `application/json`)
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.post(`${Url}`, formData, header);
  // }


  // ValuationLineItemsList() {

  //   let Url = this.apiBaseUrls + 'valuationLineItems/' + 'vehicleValuationsList'
  //   // alert(Url)
  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', `application/json`)
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.get(`${Url}`, header);
  // }



  // insuranceCategorylists() {

  //   let Url = this.apiBaseUrls + 'insurance_category/insuranceCategoryList'
  //   // alert(Url)
  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', `application/json`)
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.get(`${Url}`, header);

  // }

  // InsuranceCategoryAdd(formData) {
  //   console.log(formData, "forms")
  //   let Url = this.apiBaseUrls + 'insurance_category/' + 'create'
  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.post(`${Url}`, formData, header);
  // }

  // singleData(id) {

  //   let Url = this.apiBaseUrls + 'insurance_category/show/' + id
  //   // alert(Url)
  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', `application/json`)
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.get(`${Url}`, header);

  // }

  // deleteInsurenceCategory(id) {

  //   //let Url = this.apiBaseUrls + 'cms/blog/delete/' + id
  //   let Url = this.apiBaseUrls + 'insurance_category/delete/' + id
  //   // alert(Url)
  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', `application/json`)
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.delete(`${Url}`, header);



  // }
  // updateInsuranceCate(formData, id) {
  //   console.log(formData)
  //   let Url = this.apiBaseUrls + 'insurance_category/' + 'update/' + id;
  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.put(`${Url}`, formData, header);
  // }

  // //--------------------------------------
  // VehicleMakerList(formData) {
  //   let Url = this.apiBaseUrls + 'vehicleMake/' + 'list'
  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.post(`${Url}`, formData, header);
  // }

  // vehiclelist() {
  //   let Url = this.apiBaseUrls + 'vehicleMake/' + 'makeList'
  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.get(`${Url}`, header);
  // }

  // VehicleMakerAdd(formData) {
  //   let Url = this.apiBaseUrls + 'vehicleMake/' + 'create'
  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', `application/json`)
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.post(`${Url}`, formData, header);
  // }

  // vehicleCategory() {
  //   //13.235.227.204:4204/vehicleMake/dropDown
  //   let Url = this.apiBaseUrls + 'vehicleMake/' + 'dropDown'
  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', `application/json`)
  //   }
  //   return this.http.get(`${Url}`, header);

  // }

  // SingleVehicle(id) {
  //   let Url = this.apiBaseUrls + 'vehicleMake/' + 'show/' + id;

  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', `application/json`)
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.get(`${Url}`, header);
  // }

  // updateVehicle(formData, id) {

  //   let Url = this.apiBaseUrls + 'vehicleMake/' + 'update/' + id;
  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', `application/json`)
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.put(`${Url}`, formData, header);
  // }


  // deleteVehicle(id) {
  //   //13.235.227.204:4204/vehicleMake/delete/1
  //   let Url = this.apiBaseUrls + 'vehicleMake/' + 'delete/' + id;
  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', `application/json`)
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.delete(`${Url}`, header);
  // }

  // //-------------------------vehicle type----------------

  // //13.235.227.204:4204/vehicleType/list

  // VehicleTypeList(formData) {
  //   let Url = this.apiBaseUrls + 'vehicleType/' + 'list'

  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', `application/json`)
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.post(`${Url}`, formData, header);
  // }

  // VehicleTypeLists() {
  //   ///vehicleType/vehicleTypeList
  //   let Url = this.apiBaseUrls + 'vehicleType/' + 'vehicleTypeList'

  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', `application/json`)
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.get(`${Url}`, header);
  // }

  // VehicleTypeAdd(formData) {
  //   let Url = this.apiBaseUrls + 'vehicleType/' + 'create'
  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', `application/json`)
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.post(`${Url}`, formData, header);
  // }

  // SingleVehicleType(id) {
  //   let Url = this.apiBaseUrls + 'vehicleType/' + 'show/' + id;

  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', `application/json`)
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.get(`${Url}`, header);
  // }
  // updateVehicleType(formData, id) {

  //   let Url = this.apiBaseUrls + 'vehicleType/' + 'update/' + id;
  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', `application/json`)
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.put(`${Url}`, formData, header);
  // }

  // deleteVehicleType(id) {

  //   let Url = this.apiBaseUrls + 'vehicleType/delete/' + id;
  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', `application/json`)
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.delete(`${Url}`, header);
  // }

  // //-----------------------------Line Items------
  // VehiclelineItemsList(formData) {
  //   let Url = this.apiBaseUrls + 'valuationLineItems/' + 'list'
  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', `application/json`)
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.post(`${Url}`, formData, header);
  // }

  // //-13.235.227.204:4204/valuationLineItems/create
  // LineItemsAdd(formData) {
  //   let Url = this.apiBaseUrls + 'valuationLineItems/' + 'create'
  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', `application/json`)
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.post(`${Url}`, formData, header);
  // }

  // //--------  13.235.227.204:4204/valuationLineItems/show/1

  // SingleLineItems(id) {
  //   let Url = this.apiBaseUrls + 'valuationLineItems/' + 'show/' + id;

  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', `application/json`)
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.get(`${Url}`, header);
  // }
  // updateLineItems(formData, id) {

  //   let Url = this.apiBaseUrls + 'valuationLineItems/' + 'update/' + id;
  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', `application/json`)
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.put(`${Url}`, formData, header);
  // }

  // deleteValuationLineItems(id) {

  //   let Url = this.apiBaseUrls + 'valuationLineItems/delete/' + id;
  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', `application/json`)
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.delete(`${Url}`, header);
  // }

  // //---------------------------depreciations------------

  // depreciationsList(formData) {
  //   // 13.235.227.204:4204/depreciations/list
  //   let Url = this.apiBaseUrls + 'depreciations/list';
  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', `application/json`)
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.post(`${Url}`, formData, header);
  // }

  // depreciationsAdd(formData) {
  //   //13.235.227.204:4204/depreciations/create
  //   let Url = this.apiBaseUrls + 'depreciations/create';
  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', `application/json`)
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.post(`${Url}`, formData, header);
  // }
  // //13.235.227.204:4204/depreciations/show/2

  // SingleDepreciations(id) {
  //   let Url = this.apiBaseUrls + 'depreciations/show/' + id;
  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', `application/json`)
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.get(`${Url}`, header);
  // }

  // Updatedepreciation(formData, id) {
  //   //13.235.227.204:4204/depreciations/update/1
  //   let Url = this.apiBaseUrls + 'depreciations/update/' + id;
  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', `application/json`)
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.put(`${Url}`, formData, header);
  // }


  // deletedepreciation(id) {

  //   let Url = this.apiBaseUrls + 'depreciations/delete/' + id;
  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', `application/json`)
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.delete(`${Url}`, header);
  // }


  // //--------------13.235.227.204:4204/vehicleCalculationLineItems/list--------------
  // ListvehicleCalculationLineItems(formData) {
  //   let Url = this.apiBaseUrls + 'vehicleCalculationLineItems/list';
  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', `application/json`)
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.post(`${Url}`, formData, header);
  // }

  // AddtvehicleCalculationLineItems(formData) {
  //   //13.235.227.204:4204/vehicleCalculationLineItems/create
  //   let Url = this.apiBaseUrls + 'vehicleCalculationLineItems/create';
  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', `application/json`)
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.post(`${Url}`, formData, header);
  // }


  // SingleVehicleCalculationLineItems(id) {
  //   //13.235.227.204:4204/vehicleCalculationLineItems/show/2
  //   let Url = this.apiBaseUrls + 'vehicleCalculationLineItems/show/' + id;
  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', `application/json`)
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.get(`${Url}`, header);
  // }

  // UpdateVehicleCalculationLineItems(formData, id) {
  //   //13.235.227.204:4204/vehicleCalculationLineItems/update/1
  //   let Url = this.apiBaseUrls + 'vehicleCalculationLineItems/update/' + id;
  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', `application/json`)
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.put(`${Url}`, formData, header);
  // }

  // DeleteVehicleCalculationlineItems(_id) {
  //   let Url = this.apiBaseUrls + 'vehicleCalculationLineItems/delete/' + _id
  //   var header = {
  //   headers: new HttpHeaders()
  //   .set('Content-Type', `application/json`)
  //   .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.delete(`${Url}`, header);
    
  //   }
    


  // //-------http://13.235.227.204:4204/vehicleCalculation/list--------

  // ListvehicleCalculation(formData) {
  //   let Url = this.apiBaseUrls + 'vehicleCalculation/list';
  //   // alert(Url)
  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', `application/json`)
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.post(`${Url}`, formData, header);
  // }

  // ListvehicleCalculationList() {
  //   let Url = this.apiBaseUrls + 'vehicleCalculation/vehicleCalculationList';
  //   // alert(Url)
  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', `application/json`)
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.get(`${Url}`, header);
  // }



  // //-----------------

  // AddtvehicleCalculation(formData) {
  //   let Url = this.apiBaseUrls + 'vehicleCalculation/create';
  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', `application/json`)
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.post(`${Url}`, formData, header);
  // }


  // SingleVehicleCalculation(id) {
  //   //http://13.235.227.204:4204/vehicleCalculation/show/4
  //   let Url = this.apiBaseUrls + 'vehicleCalculation/show/' + id;
  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', `application/json`)
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.get(`${Url}`, header);
  // }

  // UpdateVehicleCalculation(formData, id) {
  //   //http://13.235.227.204:4204/vehicleCalculation/update/1
  //   let Url = this.apiBaseUrls + 'vehicleCalculation/update/' + id;
  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', `application/json`)
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.put(`${Url}`, formData, header);
  // }

  // DeleteVehicleCalculation(_id) {
  //   let Url = this.apiBaseUrls + 'vehicleCalculation/delete/' + _id
  //   var header = {
  //   headers: new HttpHeaders()
  //   .set('Content-Type', `application/json`)
  //   .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.delete(`${Url}`, header);
    
  //   }
    


  // ListvehicleModels(formData) {
  //   //13.235.227.204:4204/vehicleModels/list
  //   let Url = this.apiBaseUrls + 'vehicleModels/list';
  //   // alert(Url)
  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', `application/json`)
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.post(`${Url}`, formData, header);
  // }
  // AddModels(formData) {
  //   let Url = this.apiBaseUrls + 'vehicleModels/create';
  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', `application/json`)
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.post(`${Url}`, formData, header);
  // }


  // SingleVehicleModel(id) {
  //   //13.235.227.204:4204/vehicleModels/show/296
  //   let Url = this.apiBaseUrls + 'vehicleModels/' + 'show/' + id;

  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', `application/json`)
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.get(`${Url}`, header);
  // }

  // UpdateVehicleModel(formData, id) {
  //   //13.235.227.204:4204/vehicleModels/update/296
  //   let Url = this.apiBaseUrls + 'vehicleModels/update/' + id;
  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', `application/json`)
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.put(`${Url}`, formData, header);
  // }

  // deleteVehiclemodel(id) {

  //   let Url = this.apiBaseUrls + 'vehicleModels/delete/'+id;
  //   // alert(Url)
  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', `application/json`)
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.delete(`${Url}`, header);
  // }

  // //13.235.227.204:4204/vehicleModels/create/data
  // AddCSVmodel(formData) {
  //   let Url = this.apiBaseUrls + 'vehicleModels/create/data';
  //   // var header = {
  //   //   headers: new HttpHeaders()
  //   //     .set('Content-Type', `application/json`)
  //   //     // .set('Authorization', `${'Bearer: ' + this.token}`)
  //   // }
  //   return this.http.post(`${Url}`, formData);

  // }

  // AddPolicyProduct(formData) {
  //   //13.235.227.204:4204/policy_product/create
  //   let Url = this.apiBaseUrls + 'policy_product/create';
  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', `application/json`)
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.post(`${Url}`, formData, header);
  // }



}

