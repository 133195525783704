import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {CompanyService} from '../../../../services/company/company.service'
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class EditComponent implements OnInit {
private id:any;
public list:any;
public editData:any;
public name:any;
public status:any;
  constructor( private CompanyService:CompanyService,private activatedRoute: ActivatedRoute,private router: Router,private toastr:ToastrService) { }

  ngOnInit() {
    this.id=this.activatedRoute.snapshot.paramMap.get("id");
   // console.log(this.id,"pass id")
 
   this.ShowCompany();
}

ShowCompany(){


  this.CompanyService.apiCall('show', this.id).subscribe(
    (res:any)=>{
      this.list=res.data.records[0]
      console.log(this.list,"edit")
     
    }
  )}

  updateCompany(){
    let formData: FormData = new FormData();
    formData.append("id", this.id);
    formData.append("name",this.name);
    formData.append("website",this.list.website);
    formData.append("short_description",this.list.short_description);
    formData.append("long_description",this.list.long_description);
    formData.append("company_logo",this.list.company_logo);
    formData.append("banner_image",this.list.banner_image);
    formData.append("address",this.list.address);
    formData.append("city",this.list.city);
    formData.append("state",this.list.state);
    formData.append("country",this.list.country);
    formData.append("pincode",this.list.pincode);
    formData.append("phone_1",this.list.phone_1);
    formData.append("is_active",'1');
    formData.append("slug",this.list.slug); 
  this.CompanyService.apiCall('update', formData).subscribe(
      (res:any) => {
        console.log(res)
        if(res.success==true){
          this.toastr.success(res.message);
          this.router.navigate(['/admin/company']);
        }else{
          this.toastr.warning(res.message);

        }
        console.log(res,"update")
       });
       

  }

  ChangeLogo(ev:any) {
    const fileList: FileList = ev.target.files;
    if(fileList.length > 0) {
       this.list.company_logo = fileList[0];
    }
    this.list.company_logo = fileList[0];
    if(ev.target.files && ev.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (ev:any) => {
        //this.imgUrlHover = ev.target.result;
        this.list.company_logo = fileList[0];
        console.log( this.list.company_logo)
      }
      reader.readAsDataURL(ev.target.files[0]);
    }
  }
  ChangeBgImage(e:any){

    const fileList: FileList = e.target.files;
    if(fileList.length > 0) {
       this.list.banner_image = fileList[0];
    }
    this.list.banner_image = fileList[0];
    if(e.target.files && e.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (e:any) => {
        //this.imgUrlHover = ev.target.result;
        this.list.banner_image = fileList[0];
        console.log( this.list.banner_image)
      }
      reader.readAsDataURL(e.target.files[0]);
    }
  }



}
