import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/Auth/auth.service'
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { from } from 'rxjs';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  private resetToken:any;
  private getToken:any;
  public confirmpswd:any;
  public pswd:any;
  constructor(private _AuthService:AuthService,private tostr:ToastrService,private router:Router) { 
    this.resetToken = window.location.pathname;
    this.getToken = this.resetToken.slice(22);
  }

  ngOnInit() {

    this._AuthService.resetPassword(this.getToken).subscribe(
      (res:any)=>{
        if(res.status == false){
            this.router.navigate(['/']);
            this.tostr.error(res.message, "Error");
          }
        }
      )

  }

  ResetPassword(){
    let formData = {
      "password": this.pswd,
      "confirmPassword":this.confirmpswd        
    };
    this._AuthService.UpdatePassword(formData,this.getToken).subscribe(
    (res:any) => {
      if(res.status==true){
        this.router.navigate(['/']);
        this.tostr.success(res.message, 'Success');
      }else{
        this.tostr.error(res.message, 'Error');
      }
    })
  }
}
