import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { apiBaseUrls } from '../config/config';
import { BehaviorSubject, Observable } from 'rxjs';

//import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ReportService {

  private apiBaseUrls: string = apiBaseUrls;
  private token = window.localStorage.getItem('token');

  constructor(private http: HttpClient) { }

  PolicyReportList(formData) {
    let Url = this.apiBaseUrls + 'report/listPolicy'


    
    var header = {
      headers: new HttpHeaders()
        .set('Content-Type', `application/json`)
        .set('Authorization', `${'Bearer: ' + this.token}`)
    }
    return this.http.post(`${Url}`, formData, header);
  }


  PolicyReportList_(formData) {
    let Url_ = this.apiBaseUrls + 'report/listPolicyAgentCode'


    
    var header = {
      headers: new HttpHeaders()
        .set('Content-Type', `application/json`)
        .set('Authorization', `${'Bearer: ' + this.token}`)
    }
    return this.http.post(`${Url_}`, formData, header);
  }



  //df

  PolicyReportList_reject(formData) {
    let Url_ = 'https://adminapi.redinsure.redianglobal.com/report/approvePolicy'
    var header = {
      headers: new HttpHeaders()
        .set('Content-Type', `application/json`)
        .set('Authorization', `${'Bearer: ' + this.token}`)
    }
    return this.http.post(`${Url_}`, formData, header);
  }

  PolicyReportList_approve(formData) {
    let Url_ = 'https://adminapi.redinsure.redianglobal.com/report/approvePolicy'  
    var header = {
      headers: new HttpHeaders()
        .set('Content-Type', `application/json`)
        .set('Authorization', `${'Bearer: ' + this.token}`)
    }
    return this.http.post(`${Url_}`, formData, header);
  }
  // delete(id) {
  //   let Url = this.apiBaseUrls + 'quote/delete/' + id;
  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', `application/json`)
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.delete(`${Url}`, header);
  // }

//   view(id){

//     let Url = this.apiBaseUrls + 'report/listPolicy' + id;
//     var header = {
//       headers: new HttpHeaders()
//         .set('Content-Type', `application/json`)
//         .set('Authorization', `${'Bearer: ' + this.token}`)
//     }
//     return this.http.get(`${Url}`, header);
//  }
 //--------------------claim report API------------------------

//  ClaimReportList(formData) {
//   let Url = this.apiBaseUrls + 'report/listPolicyClaim'
//   var header = {
//     headers: new HttpHeaders()
//       .set('Content-Type', `application/json`)
//       .set('Authorization', `${'Bearer: ' + this.token}`)
//   }
//   return this.http.post(`${Url}`, formData, header);
// }


ClaimReportList(formData) {
  let Url = this.apiBaseUrls + 'policyClaim/claimpolicyList'
  var header = {
    headers: new HttpHeaders()
      .set('Content-Type', `application/json`)
      .set('Authorization', `${'Bearer: ' + this.token}`)
  }
  return this.http.post(`${Url}`, formData, header);
}

ClaimReportstatus(formData: any) {
  const url = `${this.apiBaseUrls}policyClaim/verifyClaim/${formData.policyClaimId}/${formData.status}`;
  
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${this.token}`
  });

  return this.http.post(url, formData, { headers });
}

QuoteReportList(formData) {
  let Url = this.apiBaseUrls + 'report/listQuote'
  var header = {
    headers: new HttpHeaders()
      .set('Content-Type', `application/json`)
      .set('Authorization', `${'Bearer: ' + this.token}`)
  }
  return this.http.post(`${Url}`, formData, header);
}

TransactionReportList(formData) {
  let Url = this.apiBaseUrls + 'report/listTransaction'
  var header = {
    headers: new HttpHeaders()
      .set('Content-Type', `application/json`)
      .set('Authorization', `${'Bearer: ' + this.token}`)
  }
  return this.http.post(`${Url}`, formData, header);
}

CustomerReportList(formData) {
  let Url = this.apiBaseUrls + 'report/listCustomers'
  var header = {
    headers: new HttpHeaders()
      .set('Content-Type', `application/json`)
      .set('Authorization', `${'Bearer: ' + this.token}`)
  }
  return this.http.post(`${Url}`, formData, header);
}
CustomerDetailsList(formData,id) {
  let Url = this.apiBaseUrls + `report/listCustomers/${id}`
  var header = {
    headers: new HttpHeaders()
      .set('Content-Type', `application/json`)
      .set('Authorization', `${'Bearer: ' + this.token}`)
  }
  return this.http.post(`${Url}`, formData, header);
}


CustomerDetails(data:any) {
 console.log(data,'service');
//  let id = 763;
 
  let page = 1;
  let perpage = 5;
  let Url = this.apiBaseUrls + `report/customerDetails/${data}/${page}/${perpage}`
  var header = {
    headers: new HttpHeaders()
      .set('Content-Type', `application/json`)
      .set('Authorization', `${'Bearer: ' + this.token}`)
  }
  return this.http.get(`${Url}`, header);
}




}
