import { Component, OnInit, Output, Input, EventEmitter, ViewChild, ElementRef, NgZone, AfterViewInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgForm, FormControl } from "@angular/forms";
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable, from } from 'rxjs';

@Component({
selector: 'app-header',
templateUrl: './header.component.html',
styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

constructor( ) {

}

ngOnInit() {}

}
