import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'activeInactive'})
export class ActiveInactivePipe implements PipeTransform {
    transform(value) {
        return value ? 'Active' : 'In Active';
    }
}

@Pipe({name: 'inactiveActive'})
export class InactiveActivePipe implements PipeTransform {
    transform(value) {
        return value ? 'In Active' : 'Active';
    }
}
