import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})

export class ValidationService {

  static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    let config = {
      'ngbDate': 'Invalid Date',
      'required': 'This field is required.',
      'MustMatch': 'Confirm password should be same as new password.',
      'DontMustMatch': 'Old Password and New Password Should be diffrent.',
      'invalidNumber': 'Must contain number.',
      'invalidString': 'Must contain characters.',
      'invalidCreditCard': 'Is invalid credit card number.',
      'invalidEmailAddress': 'Please enter a valid email.',
      'invalidPassword': 'Please enter a valid password..',
      'minlength': `Minimum length must be ${validatorValue.requiredLength}`,
      'maxlength': `Maxmimum length must be ${validatorValue.requiredLength}`,
      'invalidDate': 'Please fill valid date.',
      'invalidphone': 'Please enter a valid phone.',
      'invalidName': 'Please enter a valid name.',
      'invalidNames': 'Please enter a valid information.',
      'invalidDecimalNumber': 'Please enter a valid number.',
      'invalidAlphaNumber': 'Please enter alphabets and number',
      'invalidAlphaCharsDigits': 'Please enter a valid information.',
      'fullName': 'Please enter a valid name.',
      'fileSize': 'File size should be less then 1500KB.',
      'fileType': 'File upload file with valid format.',
      'invalidDomain' : `Sorry, you can't register with this domain. Please use another email address.`
    };

    return config[validatorName];
  }




  // static creditCardValidator(control) {
  //   // Visa, MasterCard, American Express, Diners Club, Discover, JCB
  //   if (control.value.match(/^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/)) {
  //     return null;
  //   } else {
  //     return { 'invalidCreditCard': true };
  //   }
  // }

  static emailValidator(control) {
    // RFC 2822 compliant regex
    if (control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
      return null;
    } else {
      return { 'invalidEmailAddress': true };
    }
  }

  static domainEmailValidator(control) {
    // RFC 2822 compliant regex
    if (!control.value.match(/^[A-Za-z0-9._%+-]+@heritage\.co\.ke$/)) {
      return null;
    } else {
      return { 'invalidDomain': true };
    }
  }

  static passwordValidator(control) {
    // {6,100}           - Assert password is between 6 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    if (control.value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,8}$/)) {
      return null;
    } else {
      return { 'invalidPassword': true };
    }
  }


  static DontMustMatch(controlName: any, matchingControlName: any) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.DontMustMatch) {
        return;
      }
      if (control.value === matchingControl.value) {
        console.log("matchingControl", control, matchingControl);
        matchingControl.setErrors({ DontMustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }

    }
  }
  static MustMatch(controlName: any, matchingControlName: any) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.MustMatch) {
        return;
      }
      if (control.value !== matchingControl.value) {
        console.log("matchingControl", control, matchingControl);
        matchingControl.setErrors({ MustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }

    }
  }

  static validDateValidator(control) {
    let value = `${control.value}`
    console.log(value);
    return null;
  }

  static dateValidator(controlName: any) {
    return (formGroup: FormGroup) => {
      let control = formGroup.controls[controlName];
      let errors = control.errors;
      console.log(errors);
      if (errors && errors.hasOwnProperty('required')) {
        return;
      } else if (errors && errors.hasOwnProperty('ngbDate')) {
        control.setErrors({ invalidDate: true });
      } else {
        return;
      }
    }
  }
  // static nameValidator(control) {
  //   if (control.value.match(/^[a-zA-Z]{3,50}$/)) {
  //     return null;
  //   } else {
  //     return { 'invalidName': true };
  //   }
  // }

  // static nameWithspaceValidator(control) {
  //   if (control.value.match(/^[a-zA-Z][a-zA-Z ]+.{3,50}$/)) {
  //     return null;
  //   } else {
  //     return { 'invalidNames': true };
  //   }
  // }

  // static twoplaceDecimalNumber(control) {
  //   if (control.value.match(/^(10|\d)(\.[0-1][0-9]{1,2})?$/)) {
  //     return null;
  //   } else {
  //     return { 'invalidDecimalNumber': true };
  //   }
  // }

  /* New validation starts here */

  /* Email validator */
  static validateEmail(control) {
    let value = control.value.toLowerCase();
    if (value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
      return null;
    } else {
      return { 'invalidEmailAddress': true };
    }
  }

  /* Password validator */
  static validatePassword(control) {
    if (control.value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,50}$/)) {
      return null;
    } else {
      return { 'invalidPassword': true };
    }
  }
  

  static onlyChars(control) {
    if (control.value.match(/^[a-zA-Z]+$/)) {
      return null;
    } else {
      return { 'invalidNames': true };
    }

  }
  /* name with dot Validator */
  static onlyAlphabetsWithDot(control) {
    if (control.value.match(/^[a-zA-Z][a-zA-Z.]+$/)) {
      return null;
    } else {
      return { 'invalidNames': true };
    }
  }
  /* name with space, dot, &, - Validator */
  static onlyAlphaChars(control) {
    if (control.value.match(/^[a-zA-Z][a-zA-Z.\-\&\ ]+$/)) {
      return null;
    } else {
      return { 'invalidNames': true };
    }
  }
  /* onlyAlphaDigits */
  static onlyAlphaDigits(control) {
    if (control.value.match(/^[a-zA-Z0-9]*$/)) {
      return null;
    } else {
      return { 'invalidAlphaNumber': true };
    }
  }
  /*  name with space, dot, &, - ,/ with digits Validator  */
  static onlyAlphaCharsDigits(control) {
    if (control.value.match(/^[a-zA-Z0-9.\-\&\ / ]+$/)) {
      return null;
    } else {
      return { 'invalidAlphaCharsDigits': true };
    }
  }

  /* phone validator */
  static validatePhone(control) {
    let value = `${control.value}`
    if (value.match(/^[7]{1}[0-9]{8}$/)) {
      return null;
    } else {
      return { 'invalidphone': true };
    }
  }
  /* onlyDigits */
  static onlyDigits(control) {
    let value = `${control.value}`;
    if (value.match(/^[0-9]*$/)) {
      return null;
    } else {
      return { 'invalidNumber': true };
    }
  }

  static fullNameValidate(control) {
    if (control.value.match(/^[a-zA-Z][a-zA-Z. ]+$/)) {
      return null;
    } else {
      return { 'fullName': true };
    }
  }

  static fileValidation(control) {
    // console.log(control.value);
    let fileType  = ["jpg","jpeg","png","pdf"];
    let value: any = control.value;
    if(!value){
      return null;
    } else {
      let name  = value.name.split('.');
      if(value.size > 1536000) {
        return { 'fileSize': true };
      } else if(!fileType.includes(name[name.length-1].toLowerCase())) {
        return { 'fileType': true};
      } else {
        return null;
      }
    }
  }

}
