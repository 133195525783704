import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AESEncryptDecryptService } from 'src/app/services/aesencrypt-decrypt.service';
import { CustomerService } from 'src/app/services/customer/customer.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {

  constructor(private router: Router, private toastr: ToastrService, private CustomerService: CustomerService, private auth: AESEncryptDecryptService) {

  }

  // Manager details
  managers = [
    {
      id: 22,
      name: "ads",
      postalCode: "201014",
      agencyAddress: "nnnasx",
      agencyEmail: "aj@saaasw.com",
      agencyPhone: "9873057350"
    }
  ];

  // Staff and inspection days (mocked data for demo)
  staffList: any = [
    { id: 6, name: "kamal", phone: "9873057350" }
  ];

  inspectionDays = [
    { id: 36, start: "02", startMin: "30", end: "08", endMin: "45", fullDayOff: false, selected: true },
    { id: 37, start: "02", startMin: "30", end: "08", endMin: "45", fullDayOff: false, selected: true },
  ];

  showStaffModal = false;
  showCalendarModal = false;

  // Image base URL
  ImageBaseUrls = "http://example.com/images/";

  // Customer information fields
  customerFields = [
    { label: 'Name', key: 'name' },
    { label: 'Email', key: 'email' },
    { label: 'Phone No', key: 'phone' },
    { label: 'National Id', key: 'National_id' },
    { label: 'Business Name', key: 'business_name' },
    { label: 'Balance', key: 'balance' },
    { label: 'Customer Type', key: 'customer_type' },
    { label: 'Gender', key: 'gender' },
    { label: 'Date of Birth', key: 'date_of_birth' },
    { label: 'Kra Pin', key: 'kra_pin' },
    { label: 'Wallet Id', key: 'mfs_custid' },
    { label: 'Occupation', key: 'occupation' },
    { label: 'Address', key: 'postel_address' },
    { label: 'Postal Code', key: 'postel_code' }
  ];

  // Customer details (for modal)
  records = {
    full_name: 'John Doe',
    image: 'profile-image.jpg',
    is_active: 1,
    created_by: 'Admin',
    created_at: new Date(),
    updated_by: 'Admin',
    updated_at: new Date(),
    name: 'John Doe',
    email: 'john.doe@example.com',
    phone: '9876543210',
    National_id: '1234567890',
    business_name: 'Doe Enterprises',
    balance: '$5000',
    customer_type: 'Premium',
    gender: 'Male',
    date_of_birth: '1980-12-15',
    kra_pin: 'ABC123',
    mfs_custid: 'XYZ789',
    occupation: 'Software Engineer',
    postel_address: '123 Main St, Nairobi',
    postel_code: '12345'
  };

  // Loader for pagination
  paginationLoader = false;


  ngOnInit(): void {
    let user = sessionStorage.getItem('username');
      console.log(user,'anshu');
      if (user == 'Admin' || user =='admin') {
        this.customerList()
      }else{
        this.booking_list();
      }
  }


  customerList() {
    this.paginationLoader = true; // Start loading
    this.CustomerService.CustomerList_({}).subscribe(
      (res: any) => {
        console.log(res, "list");
        this.paginationLoader = false; // Stop loader on success
        this.managers = res.data; // Assign the response data to managers
      },
      (error: any) => {
        this.paginationLoader = false; // Stop loader on error
        console.error("Error fetching customer list", error); // Log error
        // Optionally, you can display an error message to the user here
      }
    );
    setTimeout(() => {
      this.paginationLoader = false; // Stop loader on success
    }, 5000);
  }


  booking_list() {
    this.paginationLoader = true; // Start loading
    this.CustomerService.booking_list_({}).subscribe(
      (res: any) => {
        console.log(res, "list");
        this.paginationLoader = false; // Stop loader on success
        this.managers = res.data; // Assign the response data to managers
      },
      (error: any) => {
        this.paginationLoader = false; // Stop loader on error
        console.error("Error fetching customer list", error); // Log error
        // Optionally, you can display an error message to the user here
      }
    );
    setTimeout(() => {
      this.paginationLoader = false; // Stop loader on success
    }, 5000);
  }


  searchData() {
    console.log("Searching data...");
    // Implement your search logic here
  }

  showEntries(event: any) {
    console.log("Show entries changed", event.target.value);
    // Implement logic for showing entries based on selection
  }
  isStaffModalOpen = false;

  openStaffModal(id) {
    this.staffList = this.managers.filter((x: any) => x.manager.id == id)
    this.staffList = this.staffList.length ? this.staffList[0].staff : []
    this.isStaffModalOpen = true;
  }

  openbookingModal(managerId: number) {
    this.router.navigate([`/admin/inspection/booking/${managerId}`]);
    // this.router.navigate(['/admin/inspection/booking'], { state: { managerId } });
    console.log(managerId)

    this.staffList = this.managers.filter((x: any) => x.manager.id == managerId)
    this.staffList = this.staffList.length ? this.staffList[0].staff : []
    console.log(this.staffList, 'Filtered staff list');
    sessionStorage.setItem('staffList', JSON.stringify(this.staffList));
  }


  openCalModal(id: number) {
    let cal: any = this.managers.filter((x: any) => x.manager.id === id);
    cal = cal.length ? cal[0].booked_slots || [] : [];
    localStorage.setItem('calData', JSON.stringify(cal));
    this.router.navigate(['/admin/inspection/calendar'], { state: { calData: cal } });
  }

  closeStaffModal() {
    this.isStaffModalOpen = false;
  }

  viewStaff(managerId: number) {
    console.log(managerId)
    this.showStaffModal = true;
  }



  viewCalendarDays(managerId: number) {
    this.showCalendarModal = true;
  }

  closeCalendarModal() {
    this.showCalendarModal = false;
  }
}
