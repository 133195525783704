import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiBaseUrls } from '../config/config';
import { AuthService } from '../Auth/auth.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PolicyManagementService {
  private headersData:any = {};

  private apiUrl:any = {
    listing: apiBaseUrls + 'policy_management/list',
    show: apiBaseUrls + 'policy_management/show/',
    update: apiBaseUrls + 'policy_management/update/',
    relationship: apiBaseUrls + 'policy_management/getRelationship'
  };
  
  constructor(private authService: AuthService, private http: HttpClient) {  
      
  }

  apiCall(apiType:any, formData:any = null) {
    let Url = this.apiUrl[apiType];

    this.headersData = this.authService.setTokenHeader();
    
    switch(apiType) {
      case 'show':
        Url = Url + formData;
        return this.http.get(Url, this.headersData);
      break;

      case 'update':
        Url = Url + formData.id;
        return this.http.put(Url, formData, this.headersData);
      break;

      case 'listing':
      default:
        return this.http.post(Url, formData, this.headersData);
      break;

      case 'relationship':
        return this.http.get(Url, this.headersData);
      break;

      // case 'categories':
      //   Url = Url;
      //   if(formData.id > 0) {
      //     Url = Url + "/" + formData.id;
      //   }
      //   return this.http.get(Url, this.headersData);
      // break;
    }
  }
}
