import { Component, OnInit } from '@angular/core';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/Auth/auth.service';
import { ValidationService } from 'src/app/services/common/validation.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  changePasswordForm:FormGroup

  constructor(private formBuilder:FormBuilder, private toastrService:ToastrService, private authService:AuthService) { }

  ngOnInit() {
    this.resetForm();
  }

  resetForm(){
    this.changePasswordForm = this.formBuilder.group({
      oldPassword : [null, [Validators.required]],
      newPassword : [null, [Validators.required, Validators.pattern("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}")]],
      confirmPassword : [null, [Validators.required, Validators.pattern("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}")]],
    },{
      validators: [ValidationService.MustMatch("newPassword", "confirmPassword")]
    });
  }

  submit(){
    if(this.changePasswordForm.valid){
      let formData = this.changePasswordForm.getRawValue();
      this.authService.changePassword(formData).subscribe((res:any) =>{
        if(res.success){
            this.toastrService.success(res.message);
            this.changePasswordForm.reset();
        }else{
          this.toastrService.success('Failed to update password.');
        }
      });
    }else{
      this.changePasswordForm.markAllAsTouched();
      this.toastrService.warning('Error','All fields are mandatory!!');
    }
  }

}
