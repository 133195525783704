import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { apiBaseUrls } from '../config/config';
import { BehaviorSubject, Observable } from 'rxjs';

//import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  private apiBaseUrls: string = apiBaseUrls;
  private token = window.localStorage.getItem('token');

  constructor(private http: HttpClient) { }

  documentsList(formData) {
    let Url = this.apiBaseUrls + 'documents/' + 'list'
    var header = {
      headers: new HttpHeaders()
        .set('Content-Type', `application/json`)
        .set('Authorization', `${'Bearer: ' + this.token}`)
    }
    return this.http.post(`${Url}`, formData, header);
  }

  AddDocument(formData) {
    //13.235.227.204:4204/documents/create/claim
    let Url = this.apiBaseUrls + 'documents/create/claim'

    var header = {
      headers: new HttpHeaders()
        .set('Authorization', `${'Bearer: ' + this.token}`)
    }
    return this.http.post(`${Url}`, formData, header);
  }

  CustomerSearch(formData) {
    //http://13.235.227.204:4204/customers/searchCustomer
   let Url = this.apiBaseUrls + 'customers/searchCustomer'
   // alert(Url)
    var header = {
      headers: new HttpHeaders()
        .set('Content-Type', `application/json`)
        .set('Authorization', `${'Bearer: ' + this.token}`)
    }
    return this.http.post(`${Url}`,formData,header);
  }


  deleteDocuments(formData) {
    let Url = this.apiBaseUrls + 'documents/' + 'delete/' + formData.id;
    alert(Url)
    var header = {
      headers: new HttpHeaders()
        .set('Content-Type', `application/json`)
        .set('Authorization', `${'Bearer: ' + this.token}`)
    }
    return this.http.post(`${Url}`, formData, header);
  }

  ShowDocuments(id) {
    //13.235.227.204:4204/documents/show/43
    let Url = this.apiBaseUrls + 'documents/' + 'show/' + id;
 
    var header = {
      headers: new HttpHeaders()
        .set('Content-Type', `application/json`)
        .set('Authorization', `${'Bearer: ' + this.token}`)
    }
    return this.http.get(`${Url}`, header);
  }

  updateDocuments(formData, id) {
    let Url = this.apiBaseUrls + 'documents/update/'+id;
    alert(Url)
    var header = {
      headers: new HttpHeaders()
        // .set('Content-Type', `application/json`)
        .set('Authorization', `${'Bearer: ' + this.token}`)
    }
    return this.http.put(`${Url}`, formData, header);

  }
}
