import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/Auth/auth.service'
import { ToastrService } from 'ngx-toastr';
import { from } from 'rxjs';
import { Router } from '@angular/router'; 
@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {
  public email:any
  constructor(
    private _AuthService:AuthService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  forgetPassword(){
    let formData ={
      "email":   this.email
    }
    this._AuthService.forgetPassword(formData).subscribe(
      (res:any) => {
        if(res.status==true){
          this.toastr.success(res.message);
          //this.router.navigate(['/login']);
        } else if(res.status==false){
        this.toastr.warning(res.message);
        } 
      });
  }  

}
