import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../Auth/auth.service'
import { Observable } from 'rxjs';
import { parse } from 'querystring';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private AuthService: AuthService, private router: Router) {

  }

  canActivate(): boolean {

    if (this.AuthService.isLoginCheck() && this.validateSession()) {
      return true
    } else {
      setTimeout(() => {
        let myItem = window.localStorage.getItem('user##');
        window.localStorage.clear();
        if(myItem) window.localStorage.setItem('user##',myItem);
        this.router.navigate(["/"]);
      }, 150);
      return false;
    }
  }

  validateSession(){
    let loginTimestamp:string = localStorage.getItem('_loginTimestamp');
    if(loginTimestamp == null || loginTimestamp == undefined || loginTimestamp == ""){
      return true;
    }
    let parsedTimestamp:number = parseInt(loginTimestamp);
    return new Date().getTime() < parsedTimestamp;

  }


}
