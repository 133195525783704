import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { ToastrService } from 'ngx-toastr';
import { AESEncryptDecryptService } from 'src/app/services/aesencrypt-decrypt.service';
import { CommissionService } from 'src/app/services/commission/commission.service';
import { NgForm } from '@angular/forms';
declare var bootstrap: any;
@Component({
  selector: 'app-booking-list',
  templateUrl: './booking-list.component.html',
  styleUrls: ['./booking-list.component.css']
})
export class BookingListComponent implements OnInit {
 managerId: number | null = null;
  bookingData: any
  private apiType: string = 'save_slots';
  storedStaffList: any[] = [];
  storedStaff: any[] = [];
  isStaffModalOpen = false;
  customer_data: any;
  config = {
    id: 'bookingListPagination',
    currentPage: 1,
    itemsPerPage: 5,
    totalItems: 0,
  };
  searchByName: string = '';
  
  showingRecords = {
    from: 1,
    to: 5,
    total: 0,
  };

  data: any[] = []; // Array to hold data
  paginatedData: any[] = []; // Array to hold paginated data
  public paginationLoader = false;
  private formData = {
    name: '',
    orderby: 'ic.id asc',
    page: this.config.currentPage,
    recordsPerPage: this.config.itemsPerPage
  };

  inspectionData = {
    user_id: '',
    inspectby: 'staff',
    tire_condition: '',
    brake_performance: '',
    engine_condition: '',
    battery_health: '',
    image1: null,
    image2: null,
    video: null,
    inspection_location :{
      "latitude": 13.08268,
      "longitude": 80.270721
  }
  };
  formSubmitted: boolean;
  constructor(
    private toastr: ToastrService,
    private commission_serv: CommissionService,
    private auth: AESEncryptDecryptService,
    private route: ActivatedRoute, private router: Router,
    private customer: CustomerService
  ) { }

  ngOnInit(): void {
    this.init_form()
    this.loadData();

  }

  user:any
  init_form() {
    
    const storedData = sessionStorage.getItem('staffList');

    //  this.user = sessionStorage.getItem('username');
    //  console.log(this.user,'djf')
    this.storedStaffList = storedData ? JSON.parse(storedData) : [];
    this.storedStaff = this.storedStaffList[0];
    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      // this.managerId = id ? +id : null;
      this.managerId = 4;
    })
    if (true && this.user == 'Admin' || this.user == 'admin') {
      this.customer.customer_listt().subscribe(
        (data) => {
          this.bookingData = data || [];
          this.config.totalItems = this.bookingData.data.records.length; 
          console.log('Booking data:', this.bookingData.data.records);
        },
        (error) => {
          console.error('Error fetching booking data:', error);
        }
      );
    } else {
      this.customer.booking_list().subscribe(
        (data) => {
          this.bookingData = data || [];
          this.config.totalItems = this.bookingData.data.records.length; 
          console.log('Booking data:', this.bookingData.data.records);
        },
        (error) => {
          console.error('Error fetching booking data:', error);
        }
      );
    }
  }
 

  
  getStaffForManager(managerId: number) {
    this.customer_data = managerId;
    this.isStaffModalOpen = true;
  }
  closeStaffModal() {
    this.isStaffModalOpen = false;
  }

  onSubmit(data: any): void {
    const formData = {
      id: this.customer_data.id,
      customer_id: this.customer_data.customer_id,
      staff_id: data.id

    };
    this.commission_serv.apiCall(this.apiType, formData).subscribe(
      (res: any) => {
        if (res.success === true) {
          this.toastr.success(res.message, "Success");
          this.isStaffModalOpen = false;
          this.init_form()
          } else {
          this.toastr.error(res.message, "Error");
        }
      });
  }

  
  onVideoSelected(event: any): void {
    const file = event.target.files[0];
    if (file) {
      this.inspectionData.video = file;
    }
  }

  
  loadData(): void {
    this.data = Array.from({ length: 100 }, (_, i) => `Item ${i + 1}`);
    this.config.totalItems = this.data.length;
    this.updatePaginatedData();
  }

  // Update paginated data
  updatePaginatedData(): void {
    const startIndex = (this.config.currentPage - 1) * this.config.itemsPerPage;
    const endIndex = startIndex + this.config.itemsPerPage;

    this.paginatedData = this.data.slice(startIndex, endIndex);

    this.showingRecords.from = startIndex + 1;
    this.showingRecords.to = Math.min(endIndex, this.config.totalItems);
    this.showingRecords.total = this.config.totalItems;
  }

  // Handle page change
  pageChanged(event: number): void {
    this.config.currentPage = event;
    this.updatePaginatedData();
  }

  // Handle items per page change
  showEntries(event: Event): void {
    const target = event.target as HTMLSelectElement;
    this.config.itemsPerPage = Number(target.value);
    this.config.currentPage = 1; // Reset to the first page
    this.updatePaginatedData();
  }

  onSubmit_(form: any): void {
    this.formSubmitted = true;
    this.inspectionData.inspection_location = {
      latitude: 13.08268,
      longitude: 80.270721
    };
    if (form.valid) {
      console.log('Form Submitted:', this.inspectionData);
  
      const formData = new FormData();
      formData.append('id', this.id);
      formData.append('user_id',  this.customer_id);
      formData.append('inspectby', this.inspectionData.inspectby);
      formData.append('tire_condition', this.inspectionData.tire_condition);
      formData.append('brake_performance', this.inspectionData.brake_performance);
      formData.append('engine_condition', this.inspectionData.engine_condition);
      formData.append('battery_health', this.inspectionData.battery_health);
      formData.append('inspection_location', JSON.stringify(this.inspectionData.inspection_location));

      if (this.inspectionData.image1) {
        formData.append('imageKey1', this.inspectionData.image1, this.inspectionData.image1.name);
        formData.append('imageKey1', this.inspectionData.image2, this.inspectionData.image2.name);
        formData.append('imageKey1', this.inspectionData.video, this.inspectionData.video.name);

      }
  
      this.customer.Inspection_verify(formData).subscribe(
        (response) => {
          console.log('API Response:', response);
          this.toastr.success('Record has been saved successfully!!',"Success")
        },
        (error) => {
          console.error('API Error:', error);
        }
      );
    } else {
      console.log('Form is invalid');
    }
  }
  


  onFileSelected(event: any, field: string): void {
    const file = event.target.files[0];
    if (file) {
      this.inspectionData[field] = file;
      console.log(`${field} selected:`, file.name);
    }
  }

  customer_id:any;
  id:any;
  Customer_detailes(detailes:any){
    console.log(detailes,'ansuh')
    this.customer_id = detailes.customer_id;
    this.id = detailes.id;
  }

}