import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { apiBaseUrls } from '../config/config';
import { BehaviorSubject, Observable } from 'rxjs';

//import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ClaimService {

  private apiBaseUrls: string = apiBaseUrls;
  private token = window.localStorage.getItem('token');

  constructor(private http: HttpClient) { }


  ClaimList(formData) {
    let Url = this.apiBaseUrls + 'policyClaim/list'
    var header = {
      headers: new HttpHeaders()
        .set('Content-Type', `application/json`)
        .set('Authorization', `${'Bearer: ' + this.token}`)
    }
    return this.http.post(`${Url}`, formData, header);
  }



  AddStepOne(formData) {

    let Url = this.apiBaseUrls + 'policyClaim/createStepOne'
    var header = {
      headers: new HttpHeaders()
        .set('Content-Type', `application/json`)
        .set('Authorization', `${'Bearer: ' + this.token}`)
    }
    return this.http.post(`${Url}`, formData, header);
  }
  AddStepTwo(formData) {
    //localhost:3000/policyClaim/createStepTwo
    let Url = this.apiBaseUrls + 'policyClaim/createStepTwo'
    var header = {
      headers: new HttpHeaders()
        .set('Content-Type', `application/json`)
        .set('Authorization', `${'Bearer: ' + this.token}`)
    }
    return this.http.post(`${Url}`, formData, header);
  }
  AddStepThree(formData) {
    //localhost:3000/policyClaim/createStepThree
    let Url = this.apiBaseUrls + 'policyClaim/createStepThree'
    var header = {
      headers: new HttpHeaders()
        .set('Content-Type', `application/json`)
        .set('Authorization', `${'Bearer: ' + this.token}`)
    }
    return this.http.post(`${Url}`, formData, header);
  }

  AddStepFour(formData) {
    let Url = this.apiBaseUrls + 'policyClaim/createStepFour'
    var header = {
      headers: new HttpHeaders()
        // .set('Content-Type', `application/x-www-form-urlencoded`)
        .set('Authorization', `${'Bearer: ' + this.token}`)
    }
    return this.http.post(`${Url}`, formData, header);
  }




  deleteCompany(formData) {
    let Url = this.apiBaseUrls + 'company/' + 'delete/' + formData.id;
    var header = {
      headers: new HttpHeaders()
        .set('Content-Type', `application/json`)
        .set('Authorization', `${'Bearer: ' + this.token}`)
    }
    return this.http.post(`${Url}`, formData, header);
  }



  // ClaimList(formData) {
  //   let Url = this.apiBaseUrls + 'policyClaim/list'
  //   var header = {
  //     headers: new HttpHeaders()
  //       .set('Content-Type', `application/x-www-form-urlencoded`)
  //       .set('Authorization', `${'Bearer: ' + this.token}`)
  //   }
  //   return this.http.post(`${Url}`, formData, header);

  // }

  PhysicalValuationList(formData) {
    //http://13.235.227.204:4204/policyManagement/list
    let Url = this.apiBaseUrls + 'policyManagement/list'
    var header = {
      headers: new HttpHeaders()
        .set('Content-Type', `application/json`)
        .set('Authorization', `${'Bearer: ' + this.token}`)
    }
    return this.http.post(`${Url}`, formData, header);
  }


  addPhysicalValuation(formData) {
    //http://13.235.227.204:4204/policyManagement/uploadPhysicalDocument
    let Url = this.apiBaseUrls + 'policyManagement/uploadPhysicalDocument'
    var header = {
      headers: new HttpHeaders()
        .set('Content-Type', `application/json`)
        .set('Authorization', `${'Bearer: ' + this.token}`)
    }
    return this.http.post(`${Url}`, formData, header);
  }
  updateClaim(formData) {
    //http://13.235.227.204:4204/policyClaim/statusUpdate
    let Url = this.apiBaseUrls + 'policyClaim/statusUpdate'
    var header = {
      headers: new HttpHeaders()
        .set('Content-Type', `application/json`)
        .set('Authorization', `${'Bearer: ' + this.token}`)
    }
    return this.http.put(`${Url}`, formData, header);


  }

}
