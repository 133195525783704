import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { apiBaseUrls } from '../config/config';
import { BehaviorSubject, Observable } from 'rxjs';

//import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  private apiBaseUrls: string = apiBaseUrls;
  private token = window.localStorage.getItem('token');

  constructor(private http: HttpClient) { }

  TransactionList(formData) {
    let Url = this.apiBaseUrls + 'paymentTransaction/list'
    var header = {
      headers: new HttpHeaders()
        .set('Content-Type', `application/json`)
        .set('Authorization', `${'Bearer: ' + this.token}`)
    }
    return this.http.post(`${Url}`, formData, header);
  }

  delete(id) {
    let Url = this.apiBaseUrls + 'paymentTransaction/delete/' + id;
    var header = {
      headers: new HttpHeaders()
        .set('Content-Type', `application/json`)
        .set('Authorization', `${'Bearer: ' + this.token}`)
    }
    return this.http.delete(`${Url}`, header);
  }

  view(id){

    let Url = this.apiBaseUrls + 'quote/show/' + id;
    var header = {
      headers: new HttpHeaders()
        .set('Content-Type', `application/json`)
        .set('Authorization', `${'Bearer: ' + this.token}`)
    }
    return this.http.get(`${Url}`, header);
 }
}
