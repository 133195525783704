import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import {apiBaseUrls} from '../config/config';
import { BehaviorSubject, Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
 private token:any;
  private apiBaseUrls:string=apiBaseUrls;
  isLogin : boolean = false;
  private userLoggedIn = new Subject<boolean>();
  
  constructor( private http: HttpClient,private router: Router) {
        this.userLoggedIn.next(false);
      }
  setUserLoggedIn(userLoggedIn: boolean) {
    this.userLoggedIn.next(userLoggedIn);
  }

  getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }
  login(formData) {

    let Url=apiBaseUrls + 'adminUsers/' +'login';

    var header = {
      headers: new HttpHeaders()
      .set('Content-Type',  `application/json`)
     }
    return this.http.post(`${Url}`,formData,header);
  }
  resendOtp(formData) {

    let Url=apiBaseUrls + 'adminUsers/' +'resendOtp';

    var header = {
      headers: new HttpHeaders()
      .set('Content-Type',  `application/json`)
     }
    return this.http.post(`${Url}`,formData,header);
  }
  
  verifyOtp(formData){

    let Url=apiBaseUrls + 'adminUsers/' +'verifyOtp';

    var header = {
      headers: new HttpHeaders()
      .set('Content-Type',  `application/json`)
     }
    return this.http.post(`${Url}`,formData,header);
  }

  
  logout(){
    this.token = this.isLoginCheck();
    let Url=apiBaseUrls + 'adminUsers/logout';
    var header = this.setTokenHeader();
    localStorage.removeItem('token');
    return this.http.get(`${Url}`, header);     
  }

  expireTime(){
    setTimeout(() => {
      this.logout();
         this.router.navigate(['/']);
        let myItem = window.localStorage.getItem('user##');
        window.localStorage.clear();
        if(myItem) window.localStorage.setItem('user##',myItem);
    }, 5*60*1000);
    
  }
  isLoginCheck():any {
    return  window.localStorage.getItem('token');


  /*  if(this._cookieService.get("cms-token")) {
      this.isLogin = true;
    }else {
      this.isLogin = false;
    } */
  }

  forgetPassword(formData){
    let Url=apiBaseUrls+'adminUsers/forgotPassword' ;
    return this.http.post(`${Url}`,formData);
  }


  resetPassword(resetToken){
   //alert(resetToken)
    let Url= apiBaseUrls+'adminUsers/reset';
    var header = this.setTokenHeader();
    return this.http.get(`${Url}`,header)
  }

  UpdatePassword(formData,resetToken){

    console.log(formData,"resettokenm")
    let Url= apiBaseUrls+'adminUsers/resetPassword';
    var header = this.setTokenHeader();
    return this.http.post(`${Url}`,formData,header)

  }

  changePassword(formData){
    let Url= apiBaseUrls+'adminUsers/changePassword';
    var header = this.setTokenHeader();
    return this.http.post(`${Url}`,formData,header)
  }

  setToken(token:any):any {
    window.localStorage.setItem('token', token);
  }

  getToken():any {
    return  window.localStorage.getItem('token');
  }

  setTokenHeader():any {
    return {
      headers: new HttpHeaders()
      .set('Content-Type',  `application/json`)
      .set('Authorization', `${'Bearer: ' + this.getToken()}`)
    };
  }

  setTokenHeaderOnly():any {
    return {
      headers: new HttpHeaders()
      .set('Authorization', `${'Bearer: ' + this.getToken()}`)
    };
  }

  verifyToken(){
    let header = this.setTokenHeader();
    let Url= apiBaseUrls+'adminUsers/verifyToken';
    return this.http.post(`${Url}`,[],header)
  }

}
