import {AuthService} from './services/Auth/auth.service';
import {MenuAuthService} from './services/common/menu-auth.service';
import {UsersService} from './services/users/users.service';
import {CmsService} from './services/cms/cms.service';
import {MenuManagementService} from './services/menu-management/menu-management.service';
import {DepartmentsService} from './services/departments/departments.service';
import {RolesService}  from './services/roles/roles.service';
import {FaqService} from './services/faq/faq.service';
import {InsuranceCategoryService} from './services/insurance-category/insurance-category.service';
// import {LeadManagementService} from './services/lead-management/leadManagament.service';
import {DocumentService} from './services/documents/document.service';
import {PolicyManagementService} from './services/policy-management/policy_management.service';
import {ClaimService} from './services/claim/claim.service';
import {CustomerService} from './services/customer/customer.service';
import {QuoteService} from './services/quote-management/quote.service';
import {TransactionService} from './services/transaction-management/transaction.service';
import {ReportService} from './services/report-management/report.service';
import {DashboardService} from './services/dashboard/dashboard.service';
import { from } from "rxjs";
import { AuthInterceptorService } from './services/Auth/auth-interceptor.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
export const services = [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptorService,
        multi: true
    },
    AuthService,
    MenuAuthService,
    UsersService,
    CmsService,
    MenuManagementService,
    DepartmentsService,
    RolesService,
    FaqService,
    InsuranceCategoryService,
    // LeadManagementService,
    DocumentService,
    PolicyManagementService,
    ClaimService,
    CustomerService,
    QuoteService,
    TransactionService,
    ReportService,
    DashboardService
 ]
