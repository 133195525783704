import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

  constructor(private toastr: ToastrService, private router:Router) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError( (requestError :HttpErrorResponse) => {
        console.log(requestError,"requestError")
        if(requestError && requestError.status == 401){
          let myItem = window.localStorage.getItem('user##');
          window.localStorage.clear();
          if(myItem) window.localStorage.setItem('user##',myItem);
          this.router.navigate(['/']);
          return next.handle(req);
        }
        else {
          let err = requestError.error ? requestError.error.message :'something went wrong'
          this.toastr.error(err, "Error");
          return throwError(() => new Error(requestError.message));
        }
      })
    );
  }
}
