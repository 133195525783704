import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { format } from 'util';
import { AuthService } from '../../services/Auth/auth.service';
import { MenuAuthService } from '../../services/common/menu-auth.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { from } from 'rxjs';
import {AESEncryptDecryptService} from '../../services/aesencrypt-decrypt.service'
declare var $: any;    

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})

export class SidebarComponent implements OnInit {
  public Menu: any;
  // public AgentMenu = [ 'Add','view'];
  public mainMenu: [any];
  public test: any;
  public testing = [];
  public testing1 = [];
  public displayName: any;
  message:string;

  //@Output() onModulePicked: EventEmitter<any> = new EventEmitter<any>();

  constructor(private auth:AESEncryptDecryptService,
     private _AuthService: AuthService,
      private router: Router,
       private toastr: ToastrService,
       private menuAuth: MenuAuthService) { }


       isActiveRoute(route: string): boolean {
        return this.router.url === '/admin/dashboard';
      }

      isAgent = false;
  ngOnInit() {
    let db = localStorage.getItem("agent##");
    if(db) this.isAgent = true;

    this.menuAuth.currentMessage.subscribe(message=> this.message = message);
    this.Menu = JSON.parse(window.localStorage.getItem("menuItem"));
    console.log(this.Menu,'modules modules');
    
    this.displayName = window.localStorage.getItem('displayName');

    //re-init the sidebar tree-view library.
    $('[data-widget="treeview"]').Treeview('init');
  }

  newMessage(obj,modules) {
    let str = JSON.stringify({is_delete_permission:modules.is_delete_permisssion});
    console.log(str,"kamal")
    let encrypt = this.auth.encrypt(str);
    console.log(encrypt,"sharma")

    window.localStorage.setItem('perms',encrypt)
    this.menuAuth.changeMessage(obj);
  }

  //public pickModule(data: any) :void {
  //  this.onModulePicked.emit(data);
  //}

  logoutAdmin() {
    this._AuthService.logout().subscribe(
      (res: any) => {        
        this.router.navigate(['/']);
        this.toastr.success(res.message, 'Success');
        let myItem = window.localStorage.getItem('user##');
        window.localStorage.clear();
        if(myItem) window.localStorage.setItem('user##',myItem);
      });
  }

  GoToDashboard() {
    this.router.navigate(['admin/dashboard'])
  }

  changePassword() {
    this.router.navigate(['/admin/change-password']);
  }
}
