import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { Select2Module } from 'ng2-select2';
import { TreeViewModule } from '@syncfusion/ej2-angular-navigations';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { EditorModule } from '@tinymce/tinymce-angular';

import {
  ActiveInactivePipe,
  InactiveActivePipe,
  SlugifyPipe
} from '../core/pipes';

const PIPES = [
  ActiveInactivePipe,
  InactiveActivePipe,
  SlugifyPipe
];

@NgModule({
  declarations: [
    ...PIPES
  ],
  imports: [
    CommonModule,
    Select2Module,
    TreeViewModule
  ],
  exports: [CommonModule, ...PIPES, TreeViewModule, CKEditorModule, EditorModule],
  providers: [DatePipe, ...PIPES]
})
export class SharedModule {
  // static forRoot(): ModuleWithProviders {
  //   return {
  //     ngModule: SharedModule,
  //     providers: [ ...PIPES ]
  //   };
  // }
}
