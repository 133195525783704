import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiBaseUrls } from '../config/config';
import { AuthService } from '../Auth/auth.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DepartmentsService {

    private headersData:any = {};

  private apiUrl:any = {
    listing: apiBaseUrls + 'department/list',
    create: apiBaseUrls + 'department/create',
    show: apiBaseUrls + 'department/show/',
    update: apiBaseUrls + 'department/update/',
    delete: apiBaseUrls + 'department/delete/'
  };
  
  constructor(private authService: AuthService, private http: HttpClient) {
  }

  apiCall(apiType:any, formData:any = null) {
    let Url = this.apiUrl[apiType];

    this.headersData = this.authService.setTokenHeader();
    
    switch(apiType) {
      case 'create':
        return this.http.post(Url, formData, this.headersData);
      break;

      case 'show':
        Url = Url + formData;
        return this.http.get(Url, this.headersData);
      break;

      case 'update':
        Url = Url + formData.id;
        return this.http.put(Url, formData, this.headersData);
      break;

      case 'delete':
        Url = Url + formData;
        return this.http.delete(Url, this.headersData);
      break;

      case 'listing':
      default:
        return this.http.post(Url, formData, this.headersData);
      break;
    }
  }

}
