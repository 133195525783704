import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { apiBaseUrls } from '../config/config';
import { AuthService } from '../Auth/auth.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  private headersData:any = {};
  private tokenData:any = {};

  private apiUrl:any = {
    listing: apiBaseUrls + 'company/list',
    create: apiBaseUrls + 'company/create',
    show: apiBaseUrls + 'company/show/',
    update: apiBaseUrls + 'company/update/',
    delete: apiBaseUrls + 'company/delete/'
  };
  
  constructor(private authService: AuthService, private http: HttpClient) {
  }

  apiCall(apiType:any, formData:any = null) {
    let Url = this.apiUrl[apiType];

    this.headersData = this.authService.setTokenHeader();
    this.tokenData = this.authService.setTokenHeaderOnly();
    
    switch(apiType) {
      case 'create':
        return this.http.post(Url, formData, this.tokenData);
      break;

      case 'show':
        Url = Url + formData;
        return this.http.get(Url, this.headersData);
      break;

      case 'update':
        Url = Url + formData.get('id');
        return this.http.put(Url, formData, this.tokenData);
      break;

      case 'delete':
        Url = Url + formData;
        return this.http.delete(Url, this.headersData);
      break;

      case 'listing':
      default:
        return this.http.post(Url, formData, this.headersData);
      break;
    }
  }
}