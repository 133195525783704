import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddComponent } from './components/add/add.component';
import { ListComponent } from './components/list/list.component';
import { EditComponent } from './components/edit/edit.component';
import { CalendarComponent } from './components/calendar/calendar/calendar.component';
import { BookingComponent } from './booking/booking.component';
import { BookingListComponent } from './components/booking-list/booking-list.component';
import { CustomerInspectionDetailsComponent } from './customer-inspection-details/customer-inspection-details.component';
const routes: Routes = [
  {
    path: '',
    component: ListComponent,
    pathMatch: 'full',

  },
  {
    path: 'add',
    component: AddComponent,
    pathMatch: 'full'
  },
  {
    path: 'calendar',
    component: CalendarComponent,
    pathMatch: 'full'
  },
  {
    path: 'edit/:id',
    component: EditComponent,
    pathMatch: 'full'
  },

  {
    path: 'booking/:id',
    component: BookingComponent,
    pathMatch: 'full'
  },
  {
    path: 'listing',
    component: BookingListComponent,
    pathMatch: 'full'
  }, {
    path: 'inspection-details',
    component: CustomerInspectionDetailsComponent,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class inspectionRoutingModule { }
