import { Component, OnInit } from '@angular/core';
import { DashboardService as DataService} from '../../services/dashboard/dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  private formData = {};
  public lastMonthPolicies:any = 0;
  public lastMonthCustomers:any = 0;  
  public lastMonthCompanies:any = 0;  
  public lastMonthQuotes:any = 0;
    
  constructor(private service: DataService) { }
isAgent = false;
isadmin = false;
  userType:string;
  ngOnInit() {
    let usertype = sessionStorage.getItem("username");
    console.log(usertype);
    
    if(usertype == 'admin' || usertype == 'Admin'){
      this.isadmin = true;

    }

    let db = localStorage.getItem("agent##");
    if(db) this.isAgent = true;
    console.log(localStorage.getItem('user##'),'anshu');
    let data = localStorage.getItem('user##');
    this.userType = JSON.parse(data);
    console.log(this.userType,'anshukumar');
    this.formData['id'] = db || '';

 


    this.service.apiCall(db ? 'lastMonthPolicies_' : 'lastMonthPolicies', this.formData).subscribe(
      (res: any) => {
        if (res.success && res.data && Array.isArray(res.data.records) && res.data.records.length > 0) {
          const responseData = res.data.records[0]; // Extract the first record safely
          this.lastMonthPolicies = responseData.Total; // Set the total value if needed
    
          // Update chart data dynamically
          this.barChartLabels1 = ['Pending', 'Approved', 'Rejected'];
          this.barChartData1 = [
            {
              data: [
                Number(responseData.Pending) || 0, 
                Number(responseData.Approved) || 0, 
                Number(responseData.Rejected) || 0
              ],
              label: 'Policy Status',
              backgroundColor: ['#0883D6', '#28A745', '#DC3545'] // Example colors
            }
          ];
        } else {
          console.error('Invalid API response: ', res);
          // Optional: Handle invalid or empty response
          this.barChartLabels1 = [];
          this.barChartData1 = [];
        }
      },
      (error) => {
        console.error('API call failed: ', error);
        // Optional: Handle API error
        this.barChartLabels1 = [];
        this.barChartData1 = [];
      }
    );;
  
    this.service.apiCall(db ? 'lastMonthCustomers_' : 'lastMonthCustomers', this.formData).subscribe(
      (res: any) => {
        if (res.success && res.data && Array.isArray(res.data.records) && res.data.records.length > 0) {
          const customerData = res.data.records[0]; // Extract first record
          this.lastMonthCustomers = customerData.Total;
    
          this.barChartLabels2 = ['Active', 'Inactive', 'Pending']; // Customize labels as per your data
          this.barChartData2 = [
            {
              data: [
                Number(customerData.Active) || 0,
                Number(customerData.Inactive) || 0,
                0 
              ],
              label: 'Customer Status',
              backgroundColor: ['#4CAF50', '#dd4c3f', '#0883D6'] 
            }
          ];
        } else {
          console.error('Invalid response for lastMonthCustomers: ', res);
          this.barChartLabels2 = [];
          this.barChartData2 = [];
        }
      },
      (error) => {
        console.error('Error in lastMonthCustomers API: ', error);
        this.barChartLabels2 = [];
        this.barChartData2 = [];
      }
    );
    if (!db) {
      this.service.apiCall(db ? 'lastMonthCompanies_' : 'lastMonthCompanies', this.formData).subscribe(
        (res: any) => {
          if (res.success && res.data && Array.isArray(res.data.records) && res.data.records.length > 0) {
            const cmapnydata = res.data.records[0];
    
            this.lastMonthCompanies = cmapnydata.Total_company;
    
            // Dynamically update chart data
            this.barChartLabels4 = ['Total Company']; // Customize labels as per your data
            this.barChartData4 = [
              {
                data: [
                  Number(cmapnydata.Total_company) || 0, // Use the actual data you have
                  0 // You can update this value if you have more data (like Pending)
                ],
                label: 'Customer Status',
                backgroundColor: ['#dd4c3f'] // Example colors
              }
            ];
          } else {
            console.error('Invalid response for lastMonthCompanies: ', res);
            this.barChartLabels4 = [];
            this.barChartData4 = [];
          }
        },
        (error) => {
          console.error('Error in lastMonthCompanies API: ', error);
          this.barChartLabels4 = [];
          this.barChartData4 = [];
        }
      );
    }
    this.service.apiCall(db ? 'lastMonthQuotes' : 'lastMonthQuotes', this.formData).subscribe(
      (res: any) => {
        if (res.success && res.data && Array.isArray(res.data.records) && res.data.records.length > 0) {
          const quotesData = res.data.records[0]; // Extract first record
          this.lastMonthQuotes = quotesData.Total;
    
          // Debug: Check the values received from the API
          console.log('Quotes Data:', quotesData);
    
          // Ensure Agent_quotes and Customer_quotes are valid numbers
          const agentQuotes = Number(quotesData.Agent_quotes);
          const customerQuotes = Number(quotesData.Customer_quotes);
    
          // Debug: Check if the values are correctly parsed
          console.log('Agent Quotes:', agentQuotes);
          console.log('Customer Quotes:', customerQuotes);
    
          // Ensure Agent Quotes is a valid number or fallback to 0
          if (isNaN(agentQuotes)) {
            console.warn('Invalid Agent Quotes value, setting to 0');
          }
          if (isNaN(customerQuotes)) {
            console.warn('Invalid Customer Quotes value, setting to 0');
          }
    
          // Dynamically update chart data with fallback values
          this.barChartLabels3 = ['Agent Quotes', 'Customer Quotes'];
          this.barChartData3 = [
            {
              data: [isNaN(agentQuotes) ? 0 : agentQuotes, isNaN(customerQuotes) ? 0 : customerQuotes],
              label: 'Quote Status',
              backgroundColor: ['#FF5733', '#f7b425' ] // Example colors
            }
          ];
    
          // Manually trigger change detection to ensure chart updates
        } else {
          console.error('Invalid response for lastMonthQuotes: ', res);
          this.barChartLabels3 = [];
          this.barChartData3 = [];
        }
      },
      (error) => {
        console.error('Error in lastMonthQuotes API: ', error);
        this.barChartLabels3 = [];
        this.barChartData3 = [];
      }
    );

   
  }



  
  public barChartOptions1 = {
    responsive: true,
    scales: {
      xAxes: [{
        display: false, // Hide the X-axis (bottom labels)
        gridLines: {
          display: false // Remove grid lines from the X-axis
        }
      }],
      yAxes: [{
        display: false, // Hide the Y-axis (0 to 100 numbers)
        gridLines: {
          display: false // Remove grid lines from the Y-axis
        }
      }]
    },
    legend: { 
      display: true, 
      position: 'left' 
    }
  };
  
  public barChartLabels1 = []; // Initialize empty labels
  public barChartType1 = 'doughnut';
  public barChartData1 = []; 



  // Bar Chart 2 Data
  public barChartOptions2 = {
    responsive: true,
    scales: {
      xAxes: [{
        display: false, // Hide the X-axis (bottom labels)
        gridLines: {
          display: false // Remove grid lines from the X-axis
        }
      }],
      yAxes: [{
        display: false, // Hide the Y-axis (0 to 100 numbers)
        gridLines: {
          display: false // Remove grid lines from the Y-axis
        }
      }]
    },
    legend: { 
      display: true, 
      position: 'left' 
    }
  };
  
  public barChartLabels2: string[] = []; // Initially empty
  public barChartType2 = 'doughnut';
  public barChartData2: any[] = []; 



  public barChartOptions3 = {
    responsive: true,
    scales: {
      xAxes: [{
        display: false, // Hide the X-axis (bottom labels)
        gridLines: {
          display: false // Remove grid lines from the X-axis
        }
      }],
      yAxes: [{
        display: false, // Hide the Y-axis (0 to 100 numbers)
        gridLines: {
          display: false // Remove grid lines from the Y-axis
        }
      }]
    },
    legend: { 
      display: true, 
      position: 'left' 
    }
  };
  
  public barChartLabels3: string[] = []; // Initially empty
  public barChartType3 = 'doughnut';
  public barChartData3: any[] = []; // Initially empty


  public barChartOptions4 = {
    responsive: true,
    scales: {
      xAxes: [{
        display: false, // Hide the X-axis (bottom labels)
        gridLines: {
          display: false // Remove grid lines from the X-axis
        }
      }],
      yAxes: [{
        display: false, // Hide the Y-axis (0 to 100 numbers)
        gridLines: {
          display: false // Remove grid lines from the Y-axis
        }
      }]
    },
    legend: { 
      display: true, 
      position: 'left' 
    },
    plugins: {
      doughnut: {
        cutout: '50%' // This will reduce the size of the doughnut
      }
    }
   
  };
  
  public barChartLabels4: string[] = []; // Initially empty
  public barChartType4 = 'doughnut';
  public barChartData4: any[] = []; 


  

}


