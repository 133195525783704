import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class MenuAuthService {
   
	private messageSource = new BehaviorSubject<string>('service');
	currentMessage = this.messageSource.asObservable();

	private str = new BehaviorSubject<string>('service');
	currStr = this.str.asObservable();

  	constructor() {
  	}

  	changeMessage(message: string) {
    	this.messageSource.next(message)
    	console.log(message);
		
  	}

	  setPermission(message: string) {
    	this.str.next(message)
    	console.log(message);
		alert('kumar')
  	}
}
